import { Link} from 'react-router-dom'
import logo from '../../assets/images/Webocoo-logo.png'
import AccordionItems from './components/accordion_item'

function Offcanvas () {

  return (
    <div className="offcanvas offcanvas-start" tabIndex="-1"  id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" data-bs-scroll="true" data-bs-backdrop="false" >
      <div className="offcanvas-header ps-4">
        <img className="img-fluid" src={logo} alt="Webocco Admin" width="150" />
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        
      <nav className="navbar font-monospace">
        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li className="nav-item ps-3 w-75 py-2 bg-light"> 
            <Link className="navbar-brand ps-3" aria-current="page" to="/">
              <i className="bi bi-speedometer2"></i> Dashboard
            </Link>
          </li>
          <li className="nav-item ps-3 w-75 py-2 bg-light"> 
            <Link className="navbar-brand ps-3" aria-current="page" to="/produits/articles">
              <i className="bi bi-bag-check"></i> Produits
            </Link>
          </li>
          <li className="nav-item mt-2 w-75">
              <div className="accordion" id="accordionMenu">
                <div className="accordion-item border-0 ps-3">
                  <h2 className="accordion-header" id="headingLayouts">
                    <button className="accordion-button collapsed py-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="true" aria-controls="collapseLayouts">
                        <span><i className="bi bi-layout-text-window"></i> Layouts &nbsp;</span>
                    </button>
                  </h2>
                  
                  <div id="collapseLayouts" className="accordion-collapse collapse" aria-labelledby="headingLayouts" data-bs-parent="#accordionMenu">
                    <div className="accordion-body">
                        menu liste
                    </div>
                  </div>
                </div>
                <AccordionItems title='Mon Titre'
                  id='1'                  
                  icon ='bi bi-airplane'
                  Contenu =  
                  {
                    [
                      {to:'/Project-Home', title:'Home', icon:'bi bi-speedometer2'},
                      {to:'/Project-Home', title:'Home2', icon:'bi bi-activity'}
                  ]}
                />
                <AccordionItems title='Gestion Projets'
                  id='2'                  
                  icon ='bi bi-kanban'
                  Contenu =  
                  {
                    [
                      {to:'/Project-Home', title:'Home', icon:'bi bi-speedometer2'},
                      {to:'/Project-Home', title:'Home2', icon:'bi bi-activity'}
                  ]}
                />
                <div className='text-uppercase text-secondary my-3'>
                  <span className='text-decoration-line-through'>&nbsp;&nbsp;&nbsp;</span> 
                  <span className='p-2 fs-6 fw-light'>Pages</span>
                </div>
                <div className="accordion-item border-0 ps-3">
                  <h2 className="accordion-header mt-3" id="headingAccount">
                    <button className="accordion-button collapsed py-1 " type="button" data-bs-toggle="collapse" data-bs-target="#collapseAccount" aria-expanded="false" aria-controls="collapseAccount">
                      <span><i className="bi bi-gear"></i> Account Settings</span>
                    </button>
                  </h2>
                  <div id="collapseAccount" className="accordion-collapse collapse" aria-labelledby="headingAccount" data-bs-parent="#accordionMenu">
                    <div className="accordion-body">
                        menu liste
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0 ps-3">
                  <h2 className="accordion-header mt-3" id="headingAutentifications">
                    <button className="accordion-button collapsed py-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAutentifications" aria-expanded="false" aria-controls="collapseAutentifications">
                      <span><i className="bi bi-unlock"></i> Autentifications</span>
                    </button>
                  </h2>
                  <div id="collapseAutentifications" className="accordion-collapse collapse" aria-labelledby="headingAutentifications" data-bs-parent="#accordionMenu">
                    <div className="accordion-body">
                    menu liste
                    </div>
                  </div>
                </div>
              </div>
              
              
          </li>
          
          
         
        </ul>
        </nav>
      </div>
    </div>
  //   <div className="container-fluid "> 
  // </div>
  )
}

export default Offcanvas
