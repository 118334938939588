import {decryptData } from './utils';


export const getData = () => {
    
    let infosLocalData = localStorage.getItem('infos');

    if(!infosLocalData)
      { return null; }
    else
      {
        const salt = process.env.SALT || '6d090796-ecdf-11ea-adc1-0242ac112345';
        const originalData = decryptData(infosLocalData, salt);
        return originalData;
      }

}