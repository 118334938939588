import {useForm} from 'react-hook-form';
import axios from 'axios';
import {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {getData } from '../services/getinfos';
import Header from '../components/global/header'


function Profile() {
  
  let originalData = '';

  if (getData()!= null) { originalData = getData()}; 

  const { register, handleSubmit } = useForm();
  const { register:suppression, handleSubmit: handleSubmit2} = useForm();
  
  const [error,setError] = useState();
  const [currentUser,setcurrentUser] = useState({email:''});

  const navigate = useNavigate();

  useEffect(() => {   
    if (!localStorage.getItem("infos")){    
      navigate('/login');
    }

  axios.get("https://api.webocco.com/users/" + originalData.userId, {
      headers:{
          'Authorization': `Bearer ${originalData.token}`
      }
  })
    .then(res => {
      // console.log(res)
        if(res.data.status)
          setcurrentUser(res.data.users);
        else
          {
          localStorage.clear();
          navigate('/login');
          }             
    })
    .catch(e => console.log(e))
  }, [originalData.token,originalData.userId,navigate])   
    
 
  const onSubmit = user => {
    const answer = window.confirm("are you sure?");
      if (answer) { 
      const userData = new FormData();
      user.id = originalData.userId;
      userData.append('image', user.userPhoto[0]);
      userData.append("user", JSON.stringify(user))
      axios({
        method: "post",
        url: "https://api.webocco.com/users/update/",
        data: userData,
        headers:{
          'Authorization': `Bearer ${originalData.token}`
      }
      })
      .then(res => {                
                alert('Félicitation, votre compte a été mis à jour');
                       
            })        
      .catch(err => {        
          console.log(err);
          setError(err.response.data.error);
      })
    }
     }

     const delAcount = () => {       
      const answer = window.confirm("are you sure?");
      if (answer) {     
      axios.delete(`https://api.webocco.com/users/${originalData.userId}`, 
      {       
        headers:{
          'Authorization': `Bearer ${originalData.token}`
        }
    })      
      .then(res => { 
          alert(res.data.message);
        if(res.data.status===1){           
          localStorage.clear();
          navigate('/login');
          } 
      })
      .catch(err => {        
          setError(err.response.data.error);
      })
    }else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }

  }

  
    return (
      <>
        
        <Header /> 
          <main>                        
          <h1 className="text-center"> Profile ({currentUser.nom} {currentUser.prenom})</h1>
          <p className="text-center">  Centered element</p>
            <div className="row mx-5 border">
              <div className='col-6'>
                <h2> Modification Profile</h2>
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="form-outline mb-4">
                    <input type="text" className="form-control" defaultValue={currentUser.nom} id="Nom" {...register("nom", { required: true })} placeholder="Enter Votre Nom" />
                    <label className="form-label" htmlFor="Nom">Votre Nom</label>
                  </div>
                  <div className="form-outline mb-4">
                    <input type="text" className="form-control" defaultValue={currentUser.prenom} id="prenom" {...register("prenom", { required: true })} placeholder="Enter Votre Prénom" />
                    <label className="form-label" htmlFor="prenom">Votre Prénom</label>
                  </div>
                  <div className="form-outline mb-4">
                    <input type="email" className="form-control" id="email" defaultValue={currentUser.email} {...register("email", { required: true })} placeholder="Enter email" />
                    <label className="form-label" htmlFor="email">Email address</label>
                  </div>

                  <div className="form-outline mb-4">
                    <input type="password" id="password" className="form-control"  {...register("password", { required: true })} placeholder="Password" />
                    <label className="form-label" htmlFor="password">Password</label>
                  </div>
                  <div className="form-outline mb-4">
                    <input className="form-control" type="file" {...register("userPhoto", { required: false })} id="formFile"></input>
                    <label className="form-label" htmlFor="formFile">Photo de profil</label>
                  </div>
                  <div style={{color: "red"}}>{error && error}</div>

                  <button type="submit" className="btn btn-primary btn-block mb-4">Modifier</button>
                </form>
              </div>
              <div className='col-6'>
              <form onSubmit={handleSubmit2(delAcount)}>
                
                  <div className="form-outline mb-4">
                    <input type="email" className="form-control" id="email2" defaultValue={currentUser.email} {...suppression("email", { required: true })} placeholder="Enter email" />
                    <label className="form-label" htmlFor="email2">Email address</label>
                  </div>

                  <div className="form-outline mb-4">
                    <input type="password" id="password2" className="form-control"  {...suppression("password", { required: true })} placeholder="Password" />
                    <label className="form-label" htmlFor="password2">Password</label> 
                  </div>
                  <div style={{color: "red"}}>{error && error}</div>

                  <button type="submit" className="btn btn-primary btn-block mb-4">Supprimer</button>
                </form>
              </div>
          </div>
          
        </main>
      </>
    )
  }
  
  export default Profile
  