


function Footer() {

 return (
  <footer className="footer mt-auto py-3 bg-secondary fixed-bottom">
  <div className="container">
    <span className="text-white">Place sticky footer content here.</span>
  </div>
</footer>
    )
  }
  
  export default Footer
  