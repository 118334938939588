import background from '../assets/images/login-bg.webp'
import Webocoo from '../assets/images/Webocoo-logo.png'
import {useForm} from 'react-hook-form';
import axios from 'axios';
import {useState} from 'react';
import { useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux';
import {signIn} from '../redux/slices/userSlice';
import {encryptData} from '../services/utils';
import Register from '../components/global/register'






function Login() {
  const [showLogin, setIsShown] = useState(true);
  
  const setShownLogin = ()  =>{
    setIsShown(current => !current)
  }

  const { register, handleSubmit } = useForm();
  const [error,setError] = useState();

  const dispatch = useDispatch();     
  const navigate = useNavigate();
    
    const onSubmit = user => {
      const userData = new FormData();      
      userData.append("user", JSON.stringify(user))
      axios({
        method: "post",
        //url: "http://localhost/mvc-api/users/login/",
        url: "https://api.webocco.com/users/login/",
        data: userData,
        Headers: {
            "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          
          if(res.data.status===1){           
            const userId = res.data.userId;
            const token = res.data.token;
                  

              const originalData={
                  token:token,
                  userId:userId,
              }
              const salt = '6d090796-ecdf-11ea-adc1-0242ac112345';
              const encryptedData = encryptData(originalData, salt);
              
              localStorage.setItem('infos', encryptedData); 

              dispatch(signIn({userId:userId, token:token}));             
              navigate("/");
            }
            else
            {
              alert(res.data.message);
            } 
          

        })
        .catch(err => {        
            setError(err);
        })

    }

    return (
      <main className='w-100 row max-hv m-0'>
        
        {
          showLogin && (
           
        <>
          
            <div className="col-lg sticky-top" style={{ minHeight:'150px',backgroundImage: `url(${background})`,backgroundPosition: 'center', backgroundRepeat: 'no-repeat',  backgroundSize: 'cover' }}>
            
            </div>
           
            <div className="col-lg d-flex flex-column justify-content-center align-items-center bg-light  p-5">
            
                  <h1>LogIn to</h1>
                  <img src={Webocoo} width="150" alt="Webocco logo" />
                  <form className='w-100'  onSubmit={handleSubmit(onSubmit)}> 
                      <div className="my-4 mw-500px m-auto">
                        <input type="email" className="form-control form-control-lg" id="email" {...register("email", { required: true })}  placeholder="Enter email" />
                        <label className="form-label" htmlFor="email">Email address</label>
                      </div>

                      <div className="mw-500px mb-4 m-auto">
                        <input type="password" id="password" className="form-control form-control-lg" {...register("password", { required: true })} placeholder="Password"/>
                        <label className="form-label" htmlFor="password">Password</label>
                      </div>

                      <div className="error">{error && error}</div>                
                      <button  type="submit" className="btn btn-primary w-100 mw-500px d-block m-auto text-uppercase">Connexion</button>                  
                    </form>
                    
                    <span className="font-monospace float-right text-uppercase mt-4 badge bg-info"  onClick={function(e) {  setShownLogin();}}>or sign up (inscription)</span>
                  </div>
                </>
               
          )
        }

        {
          !showLogin && (          
        
          <>
              <div className="col-12 sticky-top" style={{ minHeight:'150px',backgroundImage: `url(${background})`,backgroundPosition: 'center', backgroundRepeat: 'no-repeat',  backgroundSize: 'cover' }}>
              
              </div>
            
              <div className="col-12 d-flex flex-column justify-content-center align-items-center bg-light  p-5">
            
                  <h1> Sign UP</h1>
                  <img src={Webocoo} width="150" alt="webocco bg"/>
                  <p className="font-monospace text-center text-uppercase mt-4 badge bg-warning text-dark "  onClick={function(e) {  setShownLogin();}}>or LogIn (Connexion)</p>
                 
                  <Register setShownLogin={setShownLogin} />
              </div>
          </>     
          )
        }
       
       </main>
    )
  }
  
  export default Login
  