import {  useSelector } from 'react-redux'
import {getData } from '../services/getinfos'
import { useNavigate} from 'react-router-dom'
import {useEffect} from 'react';

function Home() {

  const user = useSelector(state => state.user)
  const navigate = useNavigate(); 
  let originalData = '';
  
  if (getData()!= null) { originalData = getData()};

   useEffect(() => {       
      if (getData()== null) {navigate('/login') ;
    };
  },[navigate])  
  
  return (
    <>     
        <h1> Page d'accueil 🏠 <span className="token">{originalData==='' ? '' : originalData.token} </span></h1>   
        <ul className="list-group font-monospace h1 my-5 text-center">
          <li className="list-group-item text-danger">Loader spinner</li>
          <li className="list-group-item text-danger">back links</li>
          <li className="list-group-item text-danger">Project Home Page</li>         
        </ul>       
        <div className='center'>
          <div className='profile'>
            <h1>Profile</h1>
            <p>
              <strong>Name: </strong>{user?.userId}
              <strong>Name: </strong>{user.userId}
            </p>
            <p>
              <strong>Token: </strong>
              <span className="token"> {`${user.token}`}</span>
            </p>
            
           
          </div>
        </div>      
      
    </>
  )
}

export default Home
