import React, { useState,useEffect } from 'react';

function Articles() {
  
  const [listTable, setdataTable] = useState([]);

 
  useEffect(() => { 
    fetch("https://adm.gstok.com/inc/ArticlesAxios.php", {
      method: "POST",
      body: JSON.stringify({action:'fetchall'} ) 
      })
        .then(function (response) {  return response.json()})  
        .then(function (liste) {
          setdataTable(liste);
          // console.log(listTable)
        })
        //SI PROBLEME API
        .catch(function (err) {
            console.log("fetch Error")
            // alert("Veuillez nous exuser les Projets ne sont pas disponible pour le moment ")
        });
  },[]);
  
    
  console.log(listTable)

    return (
         <>
              
              
              <table className="table table-striped table-hover border">
                <thead>
                  <tr>
                    <th scope="col">Désignation</th>
                    <th scope="col">Prix A</th>
                    <th scope="col">Qté</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {listTable.map(({ idArticle, RefArticle,Designation }) =>
                
                <tr key={idArticle}>                   
                    <th scope="row">{RefArticle}</th>
                    <td>{Designation}</td>
                    <td>Otto</td>
                    <td>@mdo</td>                                          
                </tr>
                    
                
				)}
                </tbody>
              </table>
         </>
       )
     }
     
     export default Articles