import { Outlet } from "react-router-dom";
import Header from '../components/global/header'
import Footer from '../components/global/footer'

const Layout = () => {
  return (
    <>
      <Header />
      <main className='w-96 m-auto'>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;