import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { Home, Profile, Login, Blank } from './pages'
import { Articles } from './pages/produits'
import { Default, ProdLayout } from './layouts'
import Error from './components/error'

function App() {
    
  return (
    <BrowserRouter>         
      <Routes>
        <Route element={<Default />}>
          <Route  path="/" element={<Home />} />
          <Route  path="/Profile" element={<Profile />} />                
          <Route  path="/Blank" element={<Blank />} /> 
          <Route  path="/*" element={<Error />} />
        </Route>
        <Route element={<ProdLayout />}>
          <Route  path="/produits/articles" element={<Articles />} />          
        </Route>
        <Route exact path="/Login" element={<Login />} />
      </Routes>
    </BrowserRouter>
    
    
  );
}

export default App;