import { Link} from 'react-router-dom'
function AcccordionItem({id,title, icon,Contenu}) { 
  
    return (
      <div className="accordion-item border-0 ps-3">
        <h2 className="accordion-header" id={'heading'+ id}>
          <button className="accordion-button collapsed py-1" type="button" 
          data-bs-toggle="collapse" data-bs-target={'#collapse'+ id} 
          aria-expanded="true" aria-controls={'collapse'+ id}>
              <span><i className={icon}></i> {title} &nbsp;</span>
          </button>
        </h2>
        
        <div id={'collapse'+ id} className="accordion-collapse collapse" 
        aria-labelledby={'heading'+ id} data-bs-parent="#accordionMenu">
          <div className="accordion-body">
            {Contenu.map((element,index) => {
              return <Link key={index} className="navbar-brand ps-3" aria-current="page" to={element.to}>
              <i className={element.icon}></i> {element.title}
            </Link>;
            })}
          </div>
        </div>
      </div>
    )
  }
  
  export default AcccordionItem