import { Link , useNavigate} from 'react-router-dom'
import {useRef} from 'react';
import Overlay from './overlay'
import Offcanvas from './offcanvas';

function Header() {
  const OverLayMethodes = useRef(null); // refer to child Overlay Meyhodes


  const history = useNavigate();
  const logout = () => {
    localStorage.clear();
    history('/login') ;
  }
     
  const close_offcanvas =() => {
    OverLayMethodes.RemoveOverly();
    document.querySelector('.mobile-offcanvas.show').classList.remove('show');
    document.querySelector('#header').classList.add('sticky-top');
        
  }
  
  function show_offcanvas(offcanvas_id,header){       
    OverLayMethodes.AddOverly();
    document.getElementById(offcanvas_id).classList.add('show');
    document.getElementById(header).classList.remove('sticky-top');
    
  }
  
return (
  <>
    <header id="header" className='sticky-top'>    
      <div data-trigger="navbar_main" className='bg-light d-flex justify-content-between align-items-center'>
        <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
          <i className="bi bi-list display-6"></i> 
        </button>
        <Link className="navbar-brand d-lg-none" aria-current="page" to="/">Groupo Mania</Link>
        
       
        
        <button
          onClick={function(e) { show_offcanvas('navbar_main','header');}} 
          className="d-lg-none btn" 
          type="button">
            <i className="bi bi-list display-6"></i> 
        </button>

        
      </div>
      <nav id="navbar_main" className="mobile-offcanvas navbar navbar-expand-lg navbar-light bg-light"> 
          <div className="container-fluid">
            <div className="offcanvas-header-left">  
                <button onClick={function(e) {close_offcanvas();}} className="btn-close float-end bg-light p-1"></button>
            </div>
            <button  id="btn-offcanvas-mobile" className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasScrolling">
              <i className="bi bi-list display-6"></i>
            </button>
            <Link className="navbar-brand" aria-current="page" to="/">Groupo Mania</Link>
          
            <ul className="navbar-nav navbar-collapse">
            {
            localStorage.getItem("infos") ?
            (<li className="nav-item">
              <Link className="nav-link" to="/Profile">Profile</Link>
            </li> )
            :(<></>)
            }
            <li className="nav-item">
                <Link className="nav-link" to="/Blank">Blank</Link>
            </li>      
            {!localStorage.getItem("infos") ?(
            <>
              <li className="nav-item">
                <Link className="nav-link" to="/login">Connexion</Link>
              </li>              
            </>   ) :(     
            <button className="btn btn-light" onClick={() => logout()}>Déconnexion</button>)
            }
            </ul>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown button
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><Link className="dropdown-item" aria-current="page" to="/">Groupo Mania</Link></li>
                <li><Link className="dropdown-item" aria-current="page" to="/">Groupo Mania</Link></li>
                <li><Link className="dropdown-item" aria-current="page" to="/">Groupo Mania</Link></li>
              </ul>
            </div>
           
            
          </div>          
    </nav>  
  </header>
  <Offcanvas/>
  <Overlay  OverLayMethodes={OverLayMethodes}  close_offcanvas={close_offcanvas} /></>
  )
}

export default Header
