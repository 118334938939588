import { createSlice } from "@reduxjs/toolkit";
import {getData } from '../../services/getinfos';// redux persistance

const initialState = { 
  user: (getData()!= null ? {userId:getData().userId,token:getData().token} : {userId:'null',token:'null'} ),
  isLoggedIn: false,
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    signIn: (state, action) => {      
      state.user = {...state.user, ...action.payload}      
      state.isLoggedIn = true
    },
    signOut: (state) => {
      state.user = {userId:'null',token:'null'}
      state.isLoggedIn = false
    }
  }
})

export const {signIn, signOut} = userSlice.actions
export default userSlice.reducer