
import {useRef, useEffect} from 'react';

function Overlay ( {OverLayMethodes, close_offcanvas, AddOverly} ) {
  
  useEffect(() => {
    OverLayMethodes.AddOverly = AddOverly
    OverLayMethodes.RemoveOverly = RemoveOverly
  }, [AddOverly,OverLayMethodes])

  const ref = useRef(null); // identifier la balise rendu

  AddOverly =() => {    
    const main = ref.current;
    main.classList.add('overlay');    
  }

  function RemoveOverly () {    
    const main = ref.current;
    main.classList.remove('overlay');    
  }
  
return (
  <div  ref={ref} onClick={function(e) {close_offcanvas();}}></div> 
  )
}

export default Overlay
