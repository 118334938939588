import {useForm} from 'react-hook-form';
import axios from 'axios';
import {useState} from 'react';
// import { useNavigate   } from 'react-router-dom'

function Register({setShownLogin}) { 

  const SettogglePassword =() => {
  const togglePassword = document.querySelector("#togglePassword");
  const password = document.querySelector("#password");

    // toggle the type attribute
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);
   
    togglePassword.classList.toggle('bi-eye');
};


  const SetTextoPassword =() => {
    const togglePassword = document.querySelector("#togglePassword");
    const password = document.querySelector("#password");          
      password.setAttribute('type', 'password');
      togglePassword.classList.remove('bi-eye');
      togglePassword.classList.add('bi-eye-slash');
  };

  const { register, handleSubmit } = useForm();
  const [error,setError] = useState(); 

    
    
    const onSubmit = user => {  
      const userData = new FormData();
      userData.append('image', user.userPhoto[0]);
      userData.append("user", JSON.stringify(user))
      axios({
        method: "post",
        url: "https://api.webocco.com/users/register/",
        data: userData,
        Headers: {
            "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {

        alert(res.data.message);
        setShownLogin();
        // if(res.data.status===1)
        //   history('/login');
       })        
      .catch(err => {        
          //console.log(err);
          alert('Erreur !!!!');
          setError(err.response.data.error);
      })
      
     }


    return (
      
        
        <form className='w-100' onSubmit={handleSubmit(onSubmit)}>
          
            <div className="my-3 mw-500px m-auto">
              <input type="text" className="form-control form-control-lg" id="Nom" {...register("nom", { required: true })} placeholder="Enter Votre Nom" />
              <label className="form-label" htmlFor="Nom">Votre Nom</label>
            </div>
            <div className="my-3 mw-500px m-auto">
              <input type="text"  className="form-control form-control-lg" id="prenom" {...register("prenom", { required: true })} placeholder="Entrez Votre Prénom" />
              <label className="form-label" htmlFor="prenom">Votre Prénom</label>
            </div>
          
          
            <div className="my-3 mw-500px m-auto ">
              <input type="email" onFocus={function(e) {  SetTextoPassword();}} className="form-control form-control-lg" id="email" {...register("email", { required: true })}  placeholder="Entrez Votre mail" />
              <label className="form-label" htmlFor="email">Email address</label>
            </div>

            <div className=' my-3 mw-500px m-auto'>
              <div className="input-group">
                <input type="text" onFocus={function(e) {  SetTextoPassword();}} id="password" className="form-control form-control-lg" {...register("password", { required: true })} placeholder="Mot de Passe"/>
                <span className="input-group-text"><i className="bi bi-eye-slash" id="togglePassword" onClick={function(e) {  SettogglePassword();}}></i></span>
              
              </div>
              <label className="form-label" htmlFor="password">Password</label>
            </div>
          
          
            <div className="my-4 mw-500px m-auto ">
              <input className="form-control form-control-lg" type="file" {...register("userPhoto", { required: false })} id="formFile"></input>
              <label className="form-label" htmlFor="formFile">Photo de profil</label>
            </div>

                      
            <div className=" mt-2 py-3">     
              <button  type="submit" className="btn btn-primary w-100 mw-500px d-block m-auto text-uppercase py-2">Register</button>
              <div style={{color: "red"}}>{error && error}</div>
            </div>    
          
        </form>
     
    )
  }
  
  export default Register
  